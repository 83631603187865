



















































import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {
    ModalStatusPrepayment: () => import("./ModalStatusPrepayment.vue"),
  },
})
export default class TableStatusPrepayment extends Vue {
  formRules = {
    total: {
      label: "lbl_total",
      name: "total",
      placeholder: "lbl_total_placeholder",
      decorator: ["total"],
    },
    prepaymentUsed: {
      label: "lbl_prepayment_used",
      name: "prepaymentUsed",
      placeholder: "lbl_prepayment_used_placeholder",
      decorator: ["prepaymentUsed"],
    },
    remainingPrepayment: {
      label: "lbl_remaining_prepayment",
      name: "remainingPrepayment",
      placeholder: "lbl_remaining_prepayment_placeholder",
      decorator: ["remainingPrepayment"],
    },
    prepaymentPaid: {
      label: "lbl_prepayment_paid",
      name: "prepaymentPaid",
      placeholder: "lbl_prepayment_paid_placeholder",
      decorator: ["prepaymentPaid"],
    },
    paidStatus: {
      label: "lbl_paid_status",
      name: "paidStatus",
      placeholder: "lbl_paid_status_placeholder",
      decorator: ["paidStatus"],
    },
  };

  modal = {
    type: "" as "used" | "paid",
    show: false,
    toggle(): void {
      this.show = !this.show;
    },
  };

  get modalProps() {
    return {
      visible: this.modal.show,
      type: this.modal.type,
    };
  }

  showDetail(type: "used" | "paid"): void {
    this.modal.toggle();
    this.modal.type = type;
  }
}
